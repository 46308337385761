<template>
  <router-view />
</template>

<style>
body {
  font-family: "Barlow", sans-serif;
}

.p-button {
  padding: 0.3rem 1rem !important;
}

.title-card {
  font-weight: 500 !important;
}

.p-datatable .p-datatable-thead > tr > th {
  padding: 0.3rem 1.5rem !important;
}

.p-dialog .p-dialog-header .p-dialog-title {
  font-weight: 400 !important;
  font-size: 1.3rem !important;
}
/* .p-dialog {
  max-height: 100% !important;
} */

.p-card-body {
  min-height: 90vh;
}
.p-inputtext {
  width: 100% !important;
}
.leading-50 {
  line-height: 50px;
}

.p-calendar {
  width: 100%;
}

.p-card .p-card-content {
  padding: 0px !important;
}

/* scrollbar */

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}

::-webkit-scrollbar-thumb {
  background: #777;
  border: 0px none #ffffff;
  border-radius: 50px;
}

::-webkit-scrollbar-track {
  background: #ffffff;
  border: 0px none #ffffff;
  border-radius: 50px;
}

/* generales */
.bg-blue-palomo {
  background-color: #0033a0;
}
.bg-blue2-palomo {
  background-color: #049cdc;
}
.bg-green-palomo {
  background-color: #ccdc04 !important;
}

.text-blue-palomo {
  color: #2a3855 !important;
}

.p-sidebar-left .p-sidebar {
  width: 15rem !important;
}
</style>
