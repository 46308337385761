import { createRouter, createWebHistory } from "vue-router";

const routes = [
  //Login
  {
    path: "/",
    component: () =>
      import(/* webpackChunkName: "login1" */ "../views/pages/login/Login.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "login2" */ "../views/pages/login/Login.vue"),
  },

  //Views Admin
  {
    path: "/admin",
    name: "admin",
    component: () =>
      import(
        /* webpackChunkName: "layoyAdmin" */ "../views/layouts/AdminLayout.vue"
      ),
    children: [
      {
        path: "inicio",
        name: "inicio",
        component: () =>
          import(
            /* webpackChunkName: "inicio" */ "../views/pages/inicio/Inicio.vue"
          ),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
