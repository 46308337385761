import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import PrimeVue from "primevue/config";
import axios from "axios";
import VueAxios from "vue-axios";
import VueApexCharts from "vue3-apexcharts";

//Tailwind css
import "./styles/app.css";

//Prime VUE
import "primevue/resources/themes/tailwind-light/theme.css"; //theme
import "primevue/resources/primevue.min.css"; //core css

//icons
import "primeicons/primeicons.css";

//Font Awosome ICONS
/* import the fontawesome core */
import { library } from "@fortawesome/fontawesome-svg-core";
/* import font awesome icon component */
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
/* import specific icons */
import {
  faBars,
  faHome,
  faHouseCircleCheck,
  faSeedling,
  faTruckRampBox,
  faUserCheck,
  faUserSecret,
} from "@fortawesome/free-solid-svg-icons";
/* add icons to the library */
library.add(
  faUserSecret,
  faBars,
  faHome,
  faSeedling,
  faUserCheck,
  faTruckRampBox,
  faHouseCircleCheck
);

//Tooltips
import Tooltip from "primevue/tooltip";

createApp(App)
  .use(store)
  .use(router)
  .use(PrimeVue)
  .use(VueAxios, axios)
  .use(VueApexCharts)
  .component("font-awesome-icon", FontAwesomeIcon)
  .directive("tooltip", Tooltip)
  .mount("#app");
